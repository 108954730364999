<template>
  <div class="Section0" style="layout-grid: 15.6pt">
    <h1
      align="center"
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        mso-pagination: widow-orphan;
        text-align: center;
        background: rgb(255, 255, 255);
      "
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            mso-fareast-font-family: 微软雅黑;
            color: rgb(0, 0, 0);
            font-weight: bold;
            font-size: 24pt;
            mso-font-kerning: 22pt;
            background: rgb(255, 255, 255);
            mso-shading: rgb(255, 255, 255);
          "
          >HOLOWITS Privacy Notice</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            mso-fareast-font-family: 微软雅黑;
            color: rgb(0, 0, 0);
            font-weight: bold;
            font-size: 24pt;
            mso-font-kerning: 22pt;
          "
          ></span
      ></b>
    </h1>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >This HOLOWITS Privacy Notice (hereinafter referred to as this "Privacy
        Notice") describes the types of information, including but not limited
        to personal data, that we may collect from you or that you may provide
        when you interact with us or use the App. By accessing, browsing, and/or
        using the App, you shall be deemed to have agreed to this Privacy
        Notice. If you do not agree to this Privacy Notice, you must stop using
        the App.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        mso-pagination: widow-orphan;
        background: rgb(255, 255, 255);
      "
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            mso-fareast-font-family: 微软雅黑;
            color: rgb(0, 0, 0);
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
            background: rgb(255, 255, 255);
            mso-shading: rgb(255, 255, 255);
          "
          >1. What Information We Collect</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            mso-fareast-font-family: 微软雅黑;
            color: rgb(0, 0, 0);
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >When you use the App, we may collect certain personal information about
        you as described in this Privacy Notice. If you use certain functions or
        services of the App, additional privacy policies/notices may
        apply.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >The information in this Privacy Notice can be divided into information
        that you provide to us and information that HOLOWITS collects.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >Personal information that you provide to us may include your email
        address and your language selection. An example is the information that
        you provide to us when you contact us for technical support.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >Personal information that we collect includes Internet traffic data,
        such as number of user visits and the time period of visiting.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="margin-top: 0pt; margin-bottom: 0pt; mso-pagination: widow-orphan"
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          >2. How We Use Your Information</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >HOLOWITS may use your personal information for the following
        purposes:</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial">&#9679; to respond to your enquiries;</font></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial"
          >&#9679; to increase the safety of the App, such as security
          protection and fraud detection;</font
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial">&#9679; for troubleshooting;</font></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial"
          >&#9679; to generate operations and maintenance reports;</font
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial"
          >&#9679; to perform the agreements we have concluded with you;</font
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="margin-left: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial"
          >&#9679; to provide other services that you request with your express
          consent;</font
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial"
          >&#9679; to meet a legal obligation, a court order, or other binding
          decision(s);</font
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial"
          >&#9679; to accomplish a purpose unrelated to those described in this
          Privacy Notice, for which we shall first notify you and, where
          required, allow you to choose whether we may use your personal
          information for such a purpose.</font
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="margin-top: 0pt; margin-bottom: 0pt; mso-pagination: widow-orphan"
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          >3. How We Process Your Information</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >We do not and shall not sell any personal information to any third
        parties. We may provide personal information to network service
        providers and other service providers on the basis of non-disclosure
        agreements.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >The following are the limited situations where we may share your
        personal information:</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >We may share your personal information with our affiliates that are
        required to use such information in accordance with the purposes
        described in this Privacy Notice, for example, to provide you with
        technical support that you request.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >We may share your personal information to comply with applicable laws
        and orders or for the purpose of public interest. We may access,
        preserve, and share your information with regulators, law enforcement,
        or others:</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial"
          >&#9679; in response to a legal request (for example, a search
          warrant, court order, or subpoena) if we have reason to believe that
          the law requires us to do so;</font
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ><font face="Arial"
          >&#9679; when we have reason to believe that it is necessary to
          detect, prevent, and address fraud, unauthorized use of HOLOWITS
          services and products, breaches of our terms or policies, or other
          harmful or illegal activities; to protect ourselves (including our
          rights, properties, and HOLOWITS services), you, or others, including
          doing so as part of investigations or regulatory enquiries; or to
          prevent death or imminent bodily harm. For example, if relevant, we
          provide information to and receive information from third-party
          partners about the reliability of your account in order to prevent
          fraud, abuse, and other harmful activities in connection with
          HOLOWITS' online and offline services.</font
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >We may share your personal information with our new owner. If HOLOWITS
        and/or all or part of our assets are ever sold or transferred, your
        personal information may be among the items sold or transferred. Under
        such a circumstance, we will notify you on the App of (i) the identity
        and contact information of the purchaser or transferee, (ii) your right
        to revoke your consent to the provision of personal information, and
        (iii) the means by which you may revoke such consent.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="margin-top: 0pt; margin-bottom: 0pt; mso-pagination: widow-orphan"
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          >4. Information Security and Retention</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >We have implemented commercially reasonable administrative, technical,
        and physical security measures that are designed to safeguard personal
        information. We anonymize users' accounts and email addresses to protect
        their privacy. We have a team dedicated to keeping your information
        secure and testing for vulnerabilities. We also conduct periodic reviews
        and assessments of the effectiveness of our security measures.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >Notwithstanding the above, no method of transmission over the Internet,
        or method of electronic storage, is 100% secure. Therefore, HOLOWITS
        cannot guarantee the absolute security of your personal information with
        existing security technologies. If you have any questions about the
        security of the App, please contact us.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >In terms of information retention, generally, we store your information
        on HOLOWITS' servers, which are established upon third-party servers,
        until you delete or edit it, or for as long as we need it to support
        your use of our App, unless a longer retention period is required or
        permitted by applicable laws.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="margin-top: 0pt; margin-bottom: 0pt; mso-pagination: widow-orphan"
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          >5. Your Rights</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >Subject to limitations in applicable laws, you have certain rights in
        respect to your personal information, such as the right of access,
        rectification, restriction, opposition, and deletion. In order to
        exercise your rights, please contact us by sending us an email. You also
        have the right to withdraw your consent at any time, free of charge, by
        sending us an email.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="margin-top: 0pt; margin-bottom: 0pt; mso-pagination: widow-orphan"
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          >6. Children's Privacy</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p
      class="p"
      style="
        text-indent: 21.2pt;
        mso-char-indent-count: 1.77;
        mso-pagination: widow-orphan;
      "
    >
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >HOLOWITS prohibits persons under the age of 18 or the age of majority
        in the relevant jurisdiction, from accessing any part of the App. The
        App is designed and intended for adults only. By using the App, you
        represent that you are at least 18 years old or have reached the age of
        majority in the relevant jurisdiction. We attach great importance to
        children's privacy. HOLOWITS does not knowingly contact or collect any
        information from persons under the age of 18 or the age of majority in
        the relevant jurisdiction, and the App does not knowingly collect
        information in any form from persons under the age of 18 or the age of
        majority in the relevant jurisdiction. If you are a parent or legal
        guardian of a person under the age of 18 or the age of majority in the
        relevant jurisdiction and believe that he or she has disclosed his or
        her personal information to us, please contact us. A parent or legal
        guardian of a person under the age of 18 or the age of majority in the
        relevant jurisdiction may review and request deletion of such person's
        personal information and prohibit the use of such information. If we
        become aware that a person under the age of 18 or the age of majority in
        the relevant jurisdiction has provided his or her personal information
        to us, we will adopt appropriate measures to delete such personal
        information.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="margin-top: 0pt; margin-bottom: 0pt; mso-pagination: widow-orphan"
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          >7. Global Operations</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >We transfer and process your information globally both in our own
        facilities and with service providers or partners, regardless of where
        you use our App. As HOLOWITS operates globally, we may transfer your
        personal information to our data centers worldwide, when processing such
        information for the purposes described in this Privacy Notice. For
        example, if you request customer services from HOLOWITS, our colleagues
        may access some of your information, irrespective of where you are
        located, in order to provide the requested services to you. All such
        data transfers shall be performed in accordance with the applicable
        laws. In particular, we warrant that all data transfers will comply with
        your applicable local data protection laws by putting in place
        appropriate safeguards. You hereby agree to such data transfer and
        acknowledge that you have the right to be informed of the appropriate
        security measures taken by HOLOWITS to protect the transfer of your
        personal information.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >Currently, we have data centers in Singapore, the United Arab Emirates
        (UAE), Brazil, and South Africa. You acknowledge and agree that we may
        transfer and store your personal information at our international data
        centers, and that the data protection laws of the country or region in
        which your information is stored or processed may be different from
        those of your own country or region, which may result in different
        levels of protection and risks for your personal information. However,
        this does not change any of our commitments to safeguard your personal
        information in accordance with this Privacy Notice.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >If you are located in the Europe Economic Area (EEA), HOLOWITS will
        transfer your personal data to the corresponding data center for
        storage. Therefore, your personal data may be processed, used, and/or
        transferred to a country or region outside the EEA. If we process, use,
        and/or transfer your personal data to a country or region outside the
        EEA, we will adopt appropriate measures to protect your privacy. In
        order to ensure that such data transfer complies with applicable laws
        and is managed properly to protect your privacy, such data transfer is
        subject to the Binding Corporate Rules (BCR), which means that we are
        legally obligated to ensure that your personal data is sufficiently and
        consistently protected during transfer.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >If you are a resident of Brazil, you can learn more about your rights,
        including how to exercise your rights under the Brazilian General Data
        Protection Law, by reading the </span
      ><span
        ><a
          href="https://holowits.com/brazil-privacy-notice"
          ><b
            ><u
              ><span
                class="20"
                style="
                  mso-spacerun: 'yes';
                  font-family: Arial;
                  color: rgb(128, 0, 128);
                  font-weight: bold;
                  text-decoration: underline;
                  text-underline: single;
                "
                >Brazil Privacy Notice</span
              ></u
            ></b
          ></a
        ></span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="margin-top: 0pt; margin-bottom: 0pt; mso-pagination: widow-orphan"
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          >8. Amendments to This Privacy Notice</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <a name="_Hlk103697793"></a
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >We may modify this Privacy Notice at any time. If we make material
        changes to this Privacy Notice, we will notify you prior to the
        effective date of such changes by posting notifications on the
        App.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p
      class="p"
      style="margin-top: 0pt; margin-bottom: 0pt; mso-pagination: widow-orphan"
    >
      <b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          >9. Contact Us</span
        ></b
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 12pt;
            mso-font-kerning: 0pt;
          "
          ></span
      ></b>
    </p>
    <p class="MsoNormal" style="mso-pagination: widow-orphan; text-align: left">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 10.5pt;
          mso-font-kerning: 1pt;
        "
        >If you have any questions or comments about our privacy practices or
        this Privacy Notice, you can contact us by giving feedback in the
        App.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          color: rgb(255, 0, 0);
          font-size: 10.5pt;
          mso-font-kerning: 1pt;
        "
        >&nbsp;</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 10.5pt;
          mso-font-kerning: 1pt;
        "
        >You can also contact us by sending an email to </span
      ><b
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: Arial;
            font-weight: bold;
            font-size: 10.5pt;
            mso-font-kerning: 1pt;
          "
          >[feedback@holowits.com]</span
        ></b
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 10.5pt;
          mso-font-kerning: 1pt;
        "
        >.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: 宋体;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
    <p class="p" style="text-indent: 21pt; mso-pagination: widow-orphan">
      <span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        >When sending an email to us, please provide: (i) your registered email
        address and your country or region of residence; and (ii) the details of
        your request or comment.</span
      ><span
        style="
          mso-spacerun: 'yes';
          font-family: Arial;
          font-size: 12pt;
          mso-font-kerning: 0pt;
        "
        ></span>
    </p>
  </div>
</template>

<script>
export default {
    name: 's200'
};
</script>

<style lang="scss" scoped>
.Section0 {
    width: 70%;
    margin: auto;
    padding: 50px;
    h1 {
        margin-bottom: 50px !important;
    }
}
</style>